<template>
  <div class="help-title">Surcharge</div>
  <div class="about">
    <div class="title">1.Calculation of length, width and height: first round up and down, then round up after
      calculation</div>
    <div class="item-cont">
      Google Chrome is the best compatible browser when using ADS. If you encounter any issue please try to refresh your
      web page by press Ctrl + F5 at same time. Also please allow popups and cookies from ADS.
    </div>
    <div class="title">2.Additional Handling Surcharge Dimension
    </div>
    <ul class="item-list">
      <li>Length > 48" (121.92 CM)</li>
      <li>width > 36" (91.44 CM)</li>
      <li>Length + Girth > 105 (266.7 CM) (started at 2021-01-04)</li>
    </ul>
    <div class="title">3. Peak - Additional Handling Surcharge
    </div>
    <ul class="item-list">
      <li>Weight > 70 lbs (31.75 KG)</li>
      <li>Weight > 50 lbs (22.68 KG) (started at 2020-01-06)</li>
    </ul>
    <div class="title">4. Oversize
    </div>
    <ul class="item-list">
      <li>Length > 96" (243.84 CM)</li>
      <li>Length + Girth > 130" (330.2 CM)</li>
    </ul>
    <div class="title">5. Ground Unauthorized Package Charge
    </div>
    <ul class="item-list">
      <li>Length > 108" (274.32 CM)</li>
      <li>Length + Girth > 165" (419.1 CM)</li>
      <li>weight > 150 lbs (68.04 KG)</li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.help-title {
  margin: 18px 24px 12px 48px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
}

.about {
  margin-left: 260px;
  width: 860px;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-1);
    margin-top: 46px
  }

  .item-cont {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-2);
  }

  .item-list {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-2);
    margin: 0;
    padding-left: 20px;
  }
}
</style>
